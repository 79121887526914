<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-select v-model="search.time_type" class="width_80">
          <el-option :value=0 label="提款时间"></el-option>
          <el-option :value=1 label="审核时间"></el-option>
        </el-select>
        <el-date-picker v-model="search.searchDate" value-format="YYYY-MM-DD HH:mm:ss" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" ></el-date-picker>

        <span class="search-box-title">订单号：</span>
        <el-input v-model="search.order_id" placeholder="请输入订单号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">ID：</span>
        <el-input v-model="search.id" placeholder="请输入ID" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input v-model="search.account" placeholder="请输入账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">上级账号：</span>
        <el-input v-model="search.agent_id" placeholder="请输入上级账号" size="medium" clearable></el-input>
      </div>
      <!-- <div class="search-box">
        <span class="search-box-title">精准：</span>
        <el-checkbox v-model="search.checked" :true-label="1" :false-label="0" size="medium"></el-checkbox>
      </div> -->
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="search.type_id" filterable placeholder="全部" size="medium" clearable>新
          <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column width="240px" prop="order" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="account" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="agent_account" label="上级账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="trad_time" label="提现时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="admin_time" label="审核时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="trad_price" label="提现金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="comment" label="前台备注" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="admin_comment" label="后台备注" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column width="100px" prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div v-if="scope.row.status == 0" style="color:#333333">审核中</div>
          <div v-if="scope.row.status == 1" style="color:#33C933">提现成功</div>
          <div v-if="scope.row.status == 2" style="color:#F33333">提现失败</div>
          <!-- <div v-if="scope.row.status == 3" style="color:#F33333">退款中</div> -->
          <div v-if="scope.row.status == 4" style="color:#33C933">退款成功</div>
          <div v-if="scope.row.status == 5" style="color:#333333">已审核待出款</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" v-if="scope.row.status == 0" @click="auditShow(scope.row)">审核</span>
          <span class="operation" @click="dialogInfo =true;row = scope.row;titleInfo = `订单详情,订单号：` + scope.row.order">查看订单详情</span>
          <!-- <span class="operation" v-if="scope.row.status == 1" @click="findOrder(scope.row)">订单查询</span> -->
          <!-- <span class="operation" v-if="scope.row.status == 1 && scope.row.buttonShow == 1" @click="(row = scope.row),audit(3)">申请退款</span> -->
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <!-- 提现审核 -->
    <el-dialog :title="title" v-model="dialog" width="700px" top="30vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <table border="1" cellspacing="0" border-collapse="collapse">
          <tr>
            <td class="tbTitle">订单号</td>
            <td class="tbContent" colspan="3">{{row.order}}</td>
          </tr>
          <tr>
            <td class="tbTitle">账号</td>
            <td class="tbContent">{{row.account}}</td>
            <td class="tbTitle">昵称</td>
            <td class="tbContent">{{row.nickname}}</td>
          </tr>
          <tr>
            <td class="tbTitle">上级账号</td>
            <td class="tbContent">{{row.agent_account}}</td>
            <td class="tbTitle">提现时间</td>
            <td class="tbContent">{{row.trad_time}}</td>
          </tr>
          <tr>
            <td class="tbTitle">提现金额</td>
            <td class="tbContent">{{row.trad_price}}</td>
            <td class="tbTitle">打码符合要求</td>
            <td class="tbContent">{{row.dama}}</td>
          </tr>
          <tr>
            <td class="tbTitle">真实姓名</td>
            <td class="tbContent">{{row.real_name}}</td>
            <td class="tbTitle">提现银行卡</td>
            <td class="tbContent">{{row.bank_type}}</td>
          </tr>
          <tr>
            <td class="tbTitle">银行卡号</td>
            <td class="tbContent">{{row.bank_id}} <img class="copy" @click="copy(row.bank_id)" src="@/assets/鲸鱼后台/copy.png" alt="" srcset=""> </td>
            <td class="tbTitle">开户地</td>
            <td class="tbContent">{{row.address}}</td>
          </tr>
          <tr>
            <td class="tbTitle">前台备注</td>
            <td class="tbContent">
              <el-input v-model="row.comment" placeholder="请输入前台备注"></el-input>
            </td>
            <td class="tbTitle">后台备注</td>
            <td class="tbContent">
              <el-input v-model="row.admin_comment" placeholder="请输入后台备注"></el-input>
            </td>
          </tr>
        </table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="audit(1)">人工提现</el-button>
          <el-button type="primary" @click="audit(5)">自动提现</el-button>
          <el-button type="primary" @click="audit(2)">拒绝提现</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog :title="titleInfo" v-model="dialogInfo" width="700px" top="30vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <table border="1" cellspacing="0" border-collapse="collapse">
          <tr>
            <td class="tbTitle">订单号</td>
            <td class="tbContent" colspan="3">{{row.order}}</td>
          </tr>
          <tr>
            <td class="tbTitle">账号</td>
            <td class="tbContent">{{row.account}}</td>
            <td class="tbTitle">昵称</td>
            <td class="tbContent">{{row.nickname}}</td>
          </tr>
          <tr>
            <td class="tbTitle">上级账号</td>
            <td class="tbContent">{{row.agent_account}}</td>
            <td class="tbTitle">提现时间</td>
            <td class="tbContent">{{row.trad_time}}</td>
          </tr>
          <tr>
            <td class="tbTitle">提现金额</td>
            <td class="tbContent">{{row.trad_price}}</td>
            <td class="tbTitle">打码符合要求</td>
            <td class="tbContent">{{row.dama}}</td>
          </tr>
          <tr>
            <td class="tbTitle">真实姓名</td>
            <td class="tbContent">{{row.real_name}}</td>
            <td class="tbTitle">提现银行卡</td>
            <td class="tbContent">{{row.bank_type}}</td>
          </tr>
          <tr>
            <td class="tbTitle">银行卡号</td>
            <td class="tbContent">{{row.bank_id}} <img class="copy" @click="copy(row.bank_id)" src="@/assets/鲸鱼后台/copy.png" alt="" srcset=""></td>
            <td class="tbTitle">开户地</td>
            <td class="tbContent">{{row.address}}</td>
          </tr>
          <tr>
            <td class="tbTitle">前台备注</td>
            <td class="tbContent">{{row.comment}}</td>
            <td class="tbTitle">后台备注</td>
            <td class="tbContent">{{row.admin_comment}}</td>
          </tr>
        </table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogInfo = false">关闭详情</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getList, audit } from "@/api/finance/withdraw";
import page from "@/components/page";
export default {
  name: "financeWithdraw",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
        id: "", // ID
        time_type:0,
        searchDate:null,
        order_id: "", //订单号
        account: "", //账号
        agent_id: "", //上级账号
        type_id: -1, //状态id
        // checked: 1,
      },
      tableData: [], //数据
      typeArr: [
        { label: "全部", value: -1 },
        { label: "审核中", value: 0 },
        { label: "提现成功", value: 1 },
        { label: "拒绝提现", value: 2 },
      ], //状态数组
      title: "",
      dialog: false,
      row: {},
      dialogInfo: false, //详情
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取数据
    getList() {
      if (!this.search.type_id && this.search.type_id !== 0) {
        this.search.type_id = -1;
      }
      let takeId = 0;
      if(this.search.id != ""){
        takeId = this.search.id;
      }
      var bdate = this.search.searchDate && this.search.searchDate.length > 0?this.search.searchDate[0]:""
      var edate = this.search.searchDate && this.search.searchDate.length > 0?this.search.searchDate[1]:""
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "id", val: takeId },
        { key: "order_id", val: this.search.order_id },
        { key: "account", val: this.search.account },
        { key: "agent_account", val: this.search.agent_id },
        { key: "status", val: this.search.type_id },
        { key: "time_type", val: this.search.time_type },
        { key: "bdate", val: bdate},
        { key: "edate", val: edate },
        // { key: "checked", val: this.search.checked },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            res.data.list.forEach(item => {
              item.buttonShow = 0;
            });
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 填充审核数据
    auditShow(row) {
      this.dialog = true;
      this.title = "提现审核,订单号：" + row.order;
      this.row = row;
    },
    // 审核
    audit(status) {
      var str = status == 2 ? "拒绝" : "同意";
      if (status == 2) {
        if(!this.row.comment || !this.row.admin_comment)
          return this.$message({
            message: "拒绝审核必须填写备注",
            type: "error",
          });
      }
      this.$prompt("你确定要" + str + "此订单：" + this.row.order + "吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
        inputErrorMessage: '谷歌验证码只能是数字'
      }).then(({ value }) => {
        audit([
          { key: "id", val: this.row.id },
          { key: "comment", val: this.row.comment },
          { key: "admin_comment", val: this.row.admin_comment },
          { key: "status", val: status },
          { key: "google_code", val: value },
        ])
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: res.data, type: "success" });
                this.dialog = false;
                this.getList();
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }).catch(() => {
      });

      // this.$confirm(
      //   "你确定要" + str + "此订单：" + this.row.order + "吗？",
      //   "提示",
      //   {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }
      // )
      //   .then(() => {
      //     if (status == 2) {
      //       if(!this.row.comment || !this.row.admin_comment)
      //       return this.$message({
      //         message: "拒绝审核必须填写备注",
      //         type: "error",
      //       });
      //     }
      //
      //     audit([
      //       { key: "id", val: this.row.id },
      //       { key: "comment", val: this.row.comment },
      //       { key: "admin_comment", val: this.row.admin_comment },
      //       { key: "status", val: status },
      //     ])
      //       .then((res) => {
      //         if (res.code == 0) {
      //           this.$message({ message: res.data, type: "success" });
      //           this.dialog = false;
      //           this.getList();
      //         } else {
      //           this.$message({ message: res.msg, type: "error" });
      //         }
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //       });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    // 复制
    copy(str) {
      var oInput = document.createElement("input");
      oInput.value = str;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({ message: "复制成功", type: "success" });
    },
    // 关闭审核清空
    closeDialog() {
      this.comment = "";
      this.row = {};
    },

    // 查询订单
    // findOrder(row) {
    //   findOrder([
    //     { key: "id", val: row.id },
    //   ]).then((res) => {
    //     if (res.code == 0) {
    //       this.tableData.forEach(item => {
    //         if(item.id == row.id) {
    //           item.buttonShow = res.data;
    //         }
    //       });
    //       this.$message({ message: "查询成功", type: "success" });
    //       // this.getList();
    //     } else {
    //       this.$message({ message: res.msg, type: "error" });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.tbTitle {
  width: 100px;
  padding: 10px;
}
.tbContent {
  width: 200px;
  padding: 10px;
}
.tbContent .copy {
  height: 20px;
  width: 20px;
  float: right;
  cursor: pointer;
}
</style>